export default class GalleryFilter {
    constructor() {
        this.elements = {
            filterItem: $('.c-advanced-gallery:not(.c-advanced-gallery__modal) .c-advanced-gallery__filter'),
        }

        this.elements.filterItem.on('click', this.filterItems);
    }

    filterItems(event) {
        event.preventDefault();
        let el = $(event.target);
        let filterClass = el.data('filter');
        let galleryItems = $('.c-advanced-gallery:not(.c-advanced-gallery__modal) .c-advanced-gallery__items');

        el.siblings().removeClass('is-active')
        el.addClass('is-active')

        $(galleryItems).children().hide();
        $(galleryItems).find('.' + filterClass).show();
    }
}