export default class GalleryToolipMobile {
    constructor(el) {

        this.elements = {
            trigger: $('.js-hotspot'),
            close: $('.js-hotspot-close'),
            body: $('body')
        }

        this.elements.trigger.on('click', (event) => this.mobileTippy(event));
        this.elements.close.on('click', (event) => this.closeMobileTippy(event));
    }

    mobileTippy(event) {
        event.preventDefault();
        let el = $(event.currentTarget);
        let template = el.attr('data-template');
        let mobileTooltip = document.getElementById(template);
        mobileTooltip.classList.add('is-active');
        this.elements.body.addClass('advanced-gallery-mobile-hotspot-is-active')
    }

    closeMobileTippy(event) {
        event.preventDefault();
        let el = $(event.currentTarget).parents().find('.c-advanced-gallery__hotspot-source.is-active');
        el.removeClass('is-active');
        this.elements.body.removeClass('advanced-gallery-mobile-hotspot-is-active')
    }
}