export default class LazyLoad {
    constructor(video) {
        this.video = video
        this.lazyLoad()
    }

    lazyLoad() {
        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver((entry) => {
                if(entry[0].isIntersecting == true) {
                    this.loadVideo()
                    this.observer.unobserve(this.video)
                }
            });
            this.observer.observe(this.video)
        }  else {
            this.loadVideo()
        }
    }

    loadVideo() {
        this.video.src = this.video.dataset.src
        return new Plyr(this.video);
    }
}