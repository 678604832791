export default function GallerySwiper() {
    let loadImages = (swiper, lazy) => {
        if(!lazy) {
            // all images will be loaded in, so no need to keep running this
            swiper.offAny('activeIndexChange')
        }

        imgix.fluid(swiper.el, {
            lazyLoad: lazy,
            onLoad: function(el) {
                $(el).addClass('has-loaded')
            }
        })
    }

    let swiperEvents = {
        init: loadImages,
        activeIndexChange: (swiper) => loadImages(swiper, false)
    }

    // Modal
    let thumbsArgs = {
        speed: 500,
        grabCursor: false,
        slidesPerView: 'auto',
        spaceBetween: 15,
    }
    let swiperthumbs = new Swiper('.swiper-thumbs', thumbsArgs )

    let swiperModalArgs = {
        speed: 500,
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next-modal',
            prevEl: '.swiper-button-prev-modal',
        },
        thumbs: {
            swiper: swiperthumbs
        },
        on: swiperEvents
    }
    let swipermodal = new Swiper('.swiper-modal', swiperModalArgs );


    let swiperGalArgs = {
        speed: 500,
        grabCursor: true,
        scrollbar: {
            el: '.swiper-scrollbar-gallery',
            draggable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next-gallery',
            prevEl: '.swiper-button-prev-gallery',
        },
        on: swiperEvents
    }
    let swipergallery = new Swiper('.swiper-gallery', swiperGalArgs )

    // video shit
    let sliderVideos = $(".swiper-slide video")
    swipergallery.on('slideChange', function () {
        var prevVideo =  $(`[data-swiper-slide-index="${this.previousIndex}"]`).find("video")
        var currentVideo =  $(`[data-swiper-slide-index="${this.realIndex}"]`).find("video")

        sliderVideos.each(function() {
            this.currentTime = 0;
        });
        prevVideo.trigger('stop')
        currentVideo.trigger('play')
    })

    $(".c-advanced-gallery--slider .c-advanced-gallery__filter").on("click", function(event) {
        event.preventDefault;
        let el = $(this)
        let filter = el.data('filter')

        el.siblings().removeClass('is-active')
        el.addClass('is-active')

        if(filter=="all"){
            $("[data-filter-slide]").removeClass("non-swiper-slide").addClass("swiper-slide").show()
            swipergallery.destroy()
            swipergallery = new Swiper('.swiper-gallery', swiperGalArgs )
        }
        else {
            $("[data-filter-slide]").not(`.${filter}`).addClass("non-swiper-slide").removeClass("swiper-slide").hide();
            $(`[data-filter-slide].${filter}`).removeClass("non-swiper-slide").addClass("swiper-slide").attr("style", null).show();
            swipergallery.destroy();
            swipergallery = new Swiper('.swiper-gallery', swiperGalArgs )
        }
    })

    //  modal go to slide
    $(".js-advanced-gallery-modal-open").on("click", function(event) {
        let sliderActiveIndex = $(event.currentTarget).attr('data-sliderint');
        swipermodal.update();
        swiperthumbs.update();
        swipermodal.slideTo(sliderActiveIndex, 0, true);
        swiperthumbs.slideTo(sliderActiveIndex, 0, true);
    })
}