export default class GalleryModal {
    constructor() {
        this.elements = {
            open: $('.js-advanced-gallery-modal-open'),
            close: $('.js-advanced-gallery-modal-close'),
        }

        this.elements.open.on('click', this.openModal)
        this.elements.close.on('click', this.closeModal)
    }

    openModal(event) {
        $('body').addClass('advanced-gallery-modal-is-active')
        $('.c-advanced-gallery__modal').addClass('is-active')
        window.dispatchEvent(new Event('resize'))
    }

    closeModal(event) {
        event.preventDefault()
        $('body').removeClass('advanced-gallery-modal-is-active')
        $('.c-advanced-gallery__modal').removeClass('is-active')
    }
}
