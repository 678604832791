import GalleryModal from './module/GalleryModal'
import GalleryFilter from "./module/GalleryFilters"
import GallerySwiper from "./module/GallerySwiper"
import GalleryTooltip from "./module/GalleryToolip"
import GalleryToolipMobile from "./module/GalleryToolipMobile"
import GalleryVideo from "./module/GalleryVideo"
import PlaybackControl from "./module/PlaybackControl"

var grid = document.getElementsByClassName('js-grid')
var columns = document.getElementsByClassName('js-columns')
var sliders = document.getElementsByClassName('js-swiper')
var hotspots = document.getElementsByClassName('js-hotspot')
// var playbackcontrols = document.getElementsByClassName('js-playback-control');
var videos = document.querySelectorAll('.c-advanced-gallery__video');

new GalleryModal

if (grid.length > 0 || columns.length > 0) {
    new GalleryFilter
}

if (sliders.length > 0) {
    new GallerySwiper
}

if (hotspots.length > 0) {

    if (window.matchMedia("(max-width: 768px)").matches) {
        new GalleryToolipMobile;
    } else {
        document.querySelectorAll('.js-hotspot').forEach(function(el) {
            new GalleryTooltip(el)
        });
    }
}

if (videos.length > 0) {
    videos.forEach(function(el) {
        new GalleryVideo(el)
    });
}
